
import tabbable from 'tabbable';
import { getCurrentInstance, watch, onMounted, ref } from 'vue';
import { useLayers } from './composables/layers';
import { useGtm } from './composables/gtm';
import TechnicalError from './components/technical-error.vue';
const MODAL_ID = 'slayer_modal';
export default {
	components: {
		TechnicalError
	},
	setup() {
		const vm = getCurrentInstance().proxy;
		const firstTabbable = ref(null);
		const { layer, technicalError, getLayer, resetLayer } = useLayers();
		const { openLayerGtm, closeLayerGtm } = useGtm();
		watch([layer, technicalError], (newValues) => {
			if (newValues[0] || newValues[1]) {
				vm.$openModal(firstTabbable.value, MODAL_ID);
			}
		});
		vm.$modalEventBus.$on('modal-closed', (modalId) => {
			if (modalId == MODAL_ID) {
				closeLayerGtm();
				resetLayer();
			}
		});
		onMounted(() => {
			const tabbables = tabbable(document.body);
			firstTabbable.value = tabbables?.[0];
			window.openSalesLayer = async (id) => {
				await getLayer(id);
				openLayerGtm(id);
			};
			window.closeSalesLayer = () => {
				vm.$closeModal(MODAL_ID);
			};
		});
		return { layer, technicalError, MODAL_ID };
	}
};
