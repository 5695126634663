import { render, staticRenderFns } from "./rco-foreign-currency-account-picker-button.vue?vue&type=template&id=3047d8e7&lang=pug&"
import script from "./rco-foreign-currency-account-picker-button.vue?vue&type=script&lang=js&"
export * from "./rco-foreign-currency-account-picker-button.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports