
import SearchWrapper from '../search-wrapper/search-wrapper.vue';
import messages from './mobile-menu-lang.json';
import { useI18n } from 'vue-i18n-composable';
import { useMenuBar } from './composables/menu-bar';
import { useMenu } from './composables/menu';
import eventBus from '../../../../common/js/event-bus/event-bus';
import { computed, ref } from 'vue';
export default {
	components: {
		SearchWrapper
	},
	props: {
		defaultSegment: {
			required: true,
			type: String
		}
	},
	i18n: { messages },
	setup(props) {
		const { t, locale } = useI18n();
		const {
			navigationbarVisible,
			sectionsClosed,
			searchbarVisible,
			hamburgerStrokeColor,
			isHungarian,
			mainSearch,
			focusInput,
			navigationToggle,
			searchToggle
		} = useMenuBar(locale);
		const {
			responsiveFrame,
			direktLink,
			segment,
			segments,
			menuItems,
			mobileApps,
			isRootLevel,
			menuName,
			service,
			addPath,
			goToUrl,
			goBack,
			menuButtonVisible
		} = useMenu(locale, props.defaultSegment);
		const notificationHeight = ref(null);
		const handleNotificationChange = (notiHeight) => {
			notificationHeight.value = notiHeight;
		};
		eventBus.$on('notification-height-change', handleNotificationChange);
		const navigationBarStyle = computed(() => {
			return { height: `calc(100vh - 72px - ${notificationHeight.value}px)` };
		});
		return {
			segment,
			segments,
			service,
			isRootLevel,
			navigationbarVisible,
			searchbarVisible,
			menuItems,
			sectionsClosed,
			mobileApps,
			menuName,
			responsiveFrame,
			direktLink,
			hamburgerStrokeColor,
			locale,
			isHungarian,
			mainSearch,
			notificationHeight,
			navigationBarStyle,
			focusInput,
			addPath,
			searchToggle,
			goToUrl,
			goBack,
			t,
			navigationToggle,
			menuButtonVisible
		};
	}
};
