
const OPEN_MODAL_BUTTON_KEY = 85; // U betű
export default {
	methods: {
		openTinyUrlModal(event) {
			if (event && event.which == OPEN_MODAL_BUTTON_KEY && event.ctrlKey && event.altKey) {
				let activeElement = document.activeElement;
				this.$openModal({ target: activeElement }, 'tiny-url-modal');
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('keydown', this.openTinyUrlModal);
		});
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.openTinyUrlModal);
	},
	computed: {
		hasTinyUrl() {
			return !!document.querySelector('#tiny-url');
		},
		tinyUrl() {
			return this.hasTinyUrl ? document.querySelector('#tiny-url').content : null;
		}
	}
};
