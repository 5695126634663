
import { isMobile } from '@ui-common/sf-js-utils';
import messages from './legal-documents-lang.json';
export default {
	i18n: { messages },
	mixins: [isMobile],
	props: {
		links: {
			required: true
		},
		homeLinks: {
			required: true
		},
		isJunior: {
			required: true
		}
	},
	computed: {
		copyrightText() {
			const copyrightYear = new Date().getFullYear();
			return `Copyright ${copyrightYear}. OTP Bank`;
		}
	}
};
