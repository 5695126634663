
import { isMobile } from '@ui-common/sf-js-utils';
import messages from './sectionbar-lang.json';
import MobileMenu from '../mobile-menu/mobile-menu.vue';
export default {
	components: { MobileMenu },
	i18n: { messages },
	mixins: [isMobile],
	props: {
		segments: {
			required: true
		},
		mainLinks: {
			required: true
		}
	},
	methods: {
		isCurrentPage(href) {
			return window.location.pathname == href;
		}
	}
};
