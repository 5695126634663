
import VueErrorMessage from '@ui-common/vue-error-message';
import rcoApi from '../../../api/rco-api';

const DEVIZA_PACKAGES = ['Deviza', 'SmartDeviza', 'MoreDeviza'];

export default {
	name: 'RcoForeignCurrencyAccountPickerButton',
	components: {
		VueErrorMessage
	},
	props: {
		buttonId: {
			default: false
		},
		buttonClass: {
			default: false
		},
		buttonLabelledBy: {
			default: false
		},
		hasZeroPage: {
			required: false
		},
		packageId: {
			required: false
		},
		withCard: {
			default: false
		},
		cardLimit: {
			required: false
		},
		cardShoppingLimit: {
			required: false
		},
		cardVirtualShoppingLimit: {
			required: false
		},
		choosedCardId: {
			required: true
		}
	},
	data: () => ({
		sending: false,
		technicalError: false
	}),
	computed: {
		params() {
			return this.withCard
				? {
						packageId: this.packageId,
						withoutCard: !this.withCard,
						choosedCardId: this.choosedCardId,
						cardLimit: this.cardLimit,
						cardShoppingLimit: this.cardShoppingLimit,
						cardVirtualShoppingLimit: this.cardVirtualShoppingLimit
				  }
				: { packageId: this.packageId, withoutCard: !this.withCard, choosedCardId: this.choosedCardId };
		}
	},
	methods: {
		async sendData() {
			if (this.packageId) {
				try {
					this.sending = true;
					this.technicalError = false;
					const response = await rcoApi.sendPackageData(this.params);
					let url = response.data.url;
					const queryString = window.location.search;
					const params = new URLSearchParams(queryString);
					if (DEVIZA_PACKAGES.includes(this.packageId)) {
						params.append('deviza-first-step', true);
					}
					if (this.hasZeroPage) {
						params.append('has-zero-page', true);
					}
					window.location.href = `${url}${Array.from(params.entries()).length > 0 ? '?' : ''}${params.toString()}`;
				} catch (err) {
					this.technicalError = true;
				} finally {
					this.sending = false;
				}
			}
		},
		async getCookie() {
			try {
				this.sending = true;
				await rcoApi.getWebsalesId();
				this.sendData();
			} catch (err) {
				this.technicalError = true;
			} finally {
				this.sending = false;
			}
		}
	}
};
