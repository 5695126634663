import tinyUrl from '../../../applications/tiny-url/tiny-url.vue';
import legalDocuments from '../footer-commons/legal-documents/legal-documents.vue';
import footerBase from '../footer-commons/lib/footer-base';
export default {
	extends: footerBase,
	components: {
		tinyUrl,
		legalDocuments
	}
};
