
import EventBus from '../../../js/event-bus/event-bus';
import VueErrorMessage from '@ui-common/vue-error-message';
import rcoApi from '../../../../api/rco-api';

export default {
	name: 'rcoSinglePackagePickerButton',
	props: {
		packagePickerHidden: {
			default: false
		},
		packageId: {
			reqired: false
		},
		buttonId: {
			reqired: true
		},
		buttonLabelledBy: {
			reqired: true
		}
	},
	components: {
		VueErrorMessage
	},
	data: () => ({
		sending: false,
		technicalError: false
	}),
	methods: {
		async sendData() {
			if (this.packagePickerHidden && this.packageId) {
				const chosenCard = window.context.rcoSingleCardPickerData.packages[this.packageId].availableCards[0];
				const cards = window.context.rcoSingleCardPickerData.cards[chosenCard];
				try {
					this.sending = true;
					const packageData = { ...cards, packageId: this.packageId, choosedCardId: chosenCard };
					const response = await rcoApi.sendPackageData(packageData);
					const url = response.data.url;
					window.location.href = url;
				} catch (err) {
					this.technicalError = true;
				} finally {
					this.sending = false;
				}
			} else {
				EventBus.$emit('singleCardData');
			}
		},
		async getCookie() {
			try {
				this.sending = true;
				await rcoApi.getWebsalesId();
				this.sendData();
			} catch (err) {
				this.technicalError = true;
			} finally {
				this.sending = false;
			}
		}
	},
	created() {
		EventBus.$on('technicalError', () => {
			this.technicalError = true;
		});
	}
};
