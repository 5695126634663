
import VueNotification from '@ui-common/vue-notification';
import notificationApi from '../../../api/notification-api';
import eventBus from '../../../common/js/event-bus/event-bus';
import getQueryParam from 'get-query-param';
const COOKIE_SEEN_NOTIFICATIONS = {
	hu: 'portal-netbank-seen-notification-hu',
	en: 'portal-netbank-seen-notification-en'
};
const NETBANK_LAYOUTS = ['APV9L', 'EPV9L'];
const NETBANK_NOTIFICATION_TYPE = 'netbank';
const FALLBACK_TIMEOUT = 15000;
export default {
	data() {
		return {
			fallbackActive: false,
			visible: false,
			client: null,
			message: null,
			fallbackTimer: null,
			serverTime: null,
			stickyTop: null,
			actualMessage: null,
			staticLoaded: false
		};
	},
	components: {
		VueNotification
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	methods: {
		setVisible(val) {
			if (!val) {
				if (this.actualMessage && !this.actualMessage.keep) {
					this.expireNotification();
				}
			}
		},
		hideModalHeader() {
			this.$modalEventBus.$emit('hide-header', 'notification');
		},
		expireNotification() {
			this.$cookie.set(COOKIE_SEEN_NOTIFICATIONS[this.$i18n.locale], this.actualMessage.id, { expires: '10Y' });
		},
		handleNotification(message, keepNotification = false) {
			this.message = message;
			if (message.notifications && message.notifications.length) {
				if (this.actualMessage && !this.actualMessage.keep && this.actualMessage.id != message.notifications[0].id) {
					this.expireNotification();
				} else if (this.actualMessage && this.actualMessage.keep) {
					this.$cookie.set(COOKIE_SEEN_NOTIFICATIONS[this.$i18n.locale], '', { expires: '10Y' });
				}
				const lastSeenNotification = this.$cookie.get(COOKIE_SEEN_NOTIFICATIONS[this.$i18n.locale]);
				if (lastSeenNotification != message.notifications[0].id) {
					const actualMessage = message.notifications[0];
					const isNetbankPage = NETBANK_LAYOUTS.includes(window?.context?.layout);
					const isNetbankNotification = actualMessage.type == NETBANK_NOTIFICATION_TYPE;
					// netbank tipusu notification csak a netbank-os oldalakon jelenjen meg
					if ((isNetbankNotification && isNetbankPage) || !isNetbankNotification) {
						this.actualMessage = actualMessage;
						this.actualMessage.keep = keepNotification;
					} else {
						this.actualMessage = null;
					}
				}
			} else {
				this.actualMessage = null;
			}
			this.visible = !!this.actualMessage;
			this.$nextTick(() => {
				eventBus.$emit('showNetbankLogin', message.loginEnabled);
			});
		},
		async showFallback() {
			if (this.staticLoaded) {
				return;
			}
			try {
				const { data } = await notificationApi.getFallbackMessage(this.$i18n.locale);
				this.staticLoaded = true;
				this.handleNotification(data);
			} catch (err) {
				console.error('Notification not available!');
			}
		},
		handleStompError() {
			if (!this.fallbackActive) {
				this.fallbackActive = true;
				if (this.actualMessage) {
					this.fallbackTimer = setTimeout(this.showFallback, FALLBACK_TIMEOUT);
				} else {
					this.showFallback();
				}
			}
		},
		connectToWS() {
			const vm = this;
			const stompConfig = {
				onConnect() {
					clearTimeout(vm.fallbackTimer);
					vm.fallbackActive = false;
					vm.client.subscribe(`/topic/public/notification-${vm.$i18n.locale}`, ({ body }) => {
						vm.handleNotification(JSON.parse(body));
					});
				},
				onStompError: vm.handleStompError,
				onWebSocketError: vm.handleStompError
			};
			this.client = notificationApi.createWSClient(stompConfig, this.$i18n.locale);
			this.client.activate();
		},
		async setPreviewMode(id) {
			try {
				const response = await notificationApi.getPreview(id);
				if (response.data?.notifications[0]) {
					this.actualMessage = response.data.notifications[0];
					this.visible = true;
				}
			} catch (error) {
				console.error('Preview not available!');
			}
		}
	},
	created() {
		const notificationId = getQueryParam('notification-id', window.location.href);
		if (notificationId) {
			this.setPreviewMode(notificationId);
		} else {
			this.connectToWS();
		}
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.keyHandler);
	}
};
