import { mediaQueries } from '../../../../common/js/media-query/media-query';
import eventBus from '../../../../common/js/event-bus/event-bus';
export default {
	mediaQueries,
	el: '#footer',
	data: {
		ariaHide: false
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	mounted() {
		eventBus.$on('toggleAriaHidden', (hide) => {
			this.ariaHide = hide;
		});
	}
};
