import { render, staticRenderFns } from "./search-wrapper.pug?vue&type=template&id=74af054b&scoped=true&lang=pug&"
import script from "./search-wrapper.js?vue&type=script&lang=js&"
export * from "./search-wrapper.js?vue&type=script&lang=js&"
import style0 from "./search-wrapper.scss?vue&type=style&index=0&id=74af054b&prod&lang=scss&scoped=true&"
import style1 from "@ui-common/vue-search/dist/vue-search.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74af054b",
  null
  
)

export default component.exports