
import { isMobile } from '@ui-common/sf-js-utils';
import messages from './searchbar-lang.json';
import { useI18n } from 'vue-i18n-composable';
import SearchWrapper from '../search-wrapper/search-wrapper.vue';
import { computed, ref } from 'vue';
import Cookie from 'js-cookie';

const COOKIE_USER_USES_NEW_IB = 'portal-netbank-uses-new-ib';
const NEW_NETBANK_URLS = {
	hu: 'https://internetbank.otpbank.hu/auth/hu/bejelentkezes?url=%2Ffooldal',
	en: 'https://internetbank.otpbank.hu/auth/en/bejelentkezes'
};

export default {
	components: { SearchWrapper },
	i18n: { messages },
	mixins: [isMobile],
	props: {
		services: {
			required: true
		},
		searchPageUrl: {
			required: true,
			default: ''
		},
		branchAtmPageUrl: {
			required: true,
			default: ''
		},
		homeLinks: {
			required: true
		},
		isJunior: {
			required: true
		}
	},
	setup() {
		const { locale } = useI18n();
		const toggledSection = ref(null);
		const servicesVisible = ref(false);

		const searchbarVisible = computed(() => toggledSection.value == 'search');
		const userUsesNewIb = computed(() => Cookie.get(COOKIE_USER_USES_NEW_IB) == 'true');
		const direktLogin = computed(() => window.context.direktLogin);
		const direktLink = computed(() => (userUsesNewIb.value ? NEW_NETBANK_URLS[locale.value] : direktLogin.value));
		const responsiveFrame = computed(() => window.context.responsiveFrame);

		return {
			searchbarVisible,
			servicesVisible,
			direktLink,
			responsiveFrame
		};
	},
	methods: {
		isCurrentPage(href) {
			return window.location.pathname == href;
		}
	}
};
